import React from 'react';
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const PopularDishes = ({ dimsum }) => {
  const navigate = useNavigate();

  // Filter dim sum items to include only those with an image, then shuffle and select 6 random items
  const getRandomDishesWithImages = () => {
    const dishesWithImages = dimsum?.filter(dish => dish?.image_url); // Only items with an image
    const shuffled = dishesWithImages?.sort(() => 0.5 - Math.random()); // Shuffle array
    return shuffled?.slice(0, 6); // Select 6 random items
  };

  const dishesToDisplay = getRandomDishesWithImages(dimsum);

  return (
    <div className="container popular-dishes-section my-5">
      <div className="text-center mb-5">
        <p className="text-pink fw-bold">The food</p>
        <h2 className="fw-bold">Our Dim Sum</h2>
      </div>

      <div className="row">
        {dishesToDisplay?.map((dish) => (
          <div className="col-md-4 col-6 mb-4" key={dish.id}>
            <div className="dish-card shadow-sm rounded">
              <img
                src={dish.image_url}
                alt={dish.name}
                className="img-fluid rounded-top dish-image mt-3"
              />
              <div className="text-center">
                <h5 className="fw-bold mb-1">{dish.name}</h5>
                <div className="d-flex align-items-center">
                  <FaStar className="text-pink" />
                  <span className="mx-1">4.5</span> {/* Assuming a default rating */}
                  <span className="fw-bold ms-auto">${dish.price}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center mt-4">
        <button className="btn btn-pink btn-lg" onClick={() => navigate('/menu')}>
          See our full menu
        </button>
      </div>
    </div>
  );
};

export default PopularDishes;
