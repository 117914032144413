import React from 'react';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import './HomePage.css'
import BannerSection from '../components/BannerSection';
import EthosSection from '../components/EthosSection';
import PopularDishes from '../components/PopularDishes';
import AppPromo from '../components/AppPromo';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';


const HomePage = ({ restaurant }) => {
  const dimSumCat = restaurant?.Categories[17]?.Items;

  return (
    <div className="homepage-wrapper">
      {/* Full-page blurred background */}
      <div className="blurred-background"></div>

      {/* Page content */}
      <div className="homepage-content">
        <div style={{ marginTop: '70px' }}>
          <HeroSection />
          <InfoSection />
          <BannerSection />
          <EthosSection />
          <PopularDishes dimsum={dimSumCat} />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
